import React from 'react';
import Bundle from './loadable';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import './App.css';
const Layout = Bundle(()=>import('./component/layout/layout'))
const Login= Bundle(()=>import('./page/login/login'));
const Home= Bundle(()=>import('./page/home/home'));
const System= Bundle(()=>import('./page/system/system'));
const User = Bundle(()=>import('./page/user/user'));
const Developer = Bundle(()=>import( './page/developer/developer'));
const Role = Bundle(()=>import('./page/role/role'));
const Roleuser = Bundle(()=>import('./page/userRole/userRole'))
const Menu = Bundle(()=>import('./page/menu/menu'));
const Log = Bundle(()=>import('./page/log/log'));
const Organize = Bundle(()=>import('./page/organize/organize'));
const Company = Bundle(()=>import('./page/company/company'));
const Catalog = Bundle(()=>import('./page/catalog/catalog'));
function App() {
  return (
    <div className="App">
        <Router>
            <Switch>
                <Route exact path='/' component={Login}/>
                <Route>
                    <Layout>
                       
                    </Layout>
                </Route>
            </Switch>
        </Router>
    </div>
  );
}

export default App;
